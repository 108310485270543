import type {FC} from 'react';

import IconFlagSVG from '@src/assets/img/flag.svg';

interface Props {
  className?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconFlag: FC<Props> = ({
  className = 'ico',
  height = '100%',
  viewBox = '0 1 22 22',
  width = '100%',
}) => {
  return (
    <IconFlagSVG
      className={className}
      fill="#819AA9"
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default IconFlag;
