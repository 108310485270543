import type {FC} from 'react';

import IconStripeSVG from '@yourcoach/shared/assets/icons/stripe.svg';

interface Props {
  className?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconStripe: FC<Props> = ({
  className = '',
  height = '100%',
  viewBox = '0 0 37 15',
  width = '100%',
}) => {
  return (
    <IconStripeSVG
      className={className}
      fill="#819AA9"
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default IconStripe;
