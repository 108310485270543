import type {FC} from 'react';
import {memo} from 'react';

import ArrowBack from '@yourcoach/shared/assets/icons/arrow-back.svg';

interface Props {
  customClass?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconPrev: FC<Props> = ({
  customClass = '',
  height = '100%',
  viewBox = '0 5 20 20',
  width = '100%',
}) => {
  return (
    <ArrowBack
      className={`${customClass}`}
      fill="#819AA9"
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default memo(IconPrev);
