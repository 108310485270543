import {type FC, useEffect} from 'react';

import {envManager} from '@yourcoach/shared/utils/env';
import {
  emitter,
  WS_RECEIVE_MESSAGE_EVENT,
} from '@yourcoach/shared/utils/eventEmitter';
import {useWS} from '@yourcoach/shared/utils/hooks';

const GET_WS_URL = () => {
  const apiEndpoint = envManager.isCore
    ? process.env.API_ENDPOINT
    : process.env.B2B_API_ENDPOINT;
  const protocol = 'wss';

  return `${apiEndpoint?.replace(/https?/, protocol)}/events/listen`;
};

const WS: FC = () => {
  const {lastWsMessage} = useWS(GET_WS_URL());

  useEffect(() => {
    if (lastWsMessage) {
      emitter.emit(WS_RECEIVE_MESSAGE_EVENT, lastWsMessage);
    }
  }, [lastWsMessage]);

  return null;
};

export default WS;
