import type {ReactNode} from 'react';
import {createContext, memo, useMemo} from 'react';

import stores from '@src/context/stores';

interface Props {
  children: ReactNode;
}

interface IAppContext {
  stores: typeof stores;
}

const Context = createContext<IAppContext>({
  stores,
});

export const Provider = memo((props: Props) => {
  const memoValue = useMemo(() => ({stores}), []);

  return (
    <Context.Provider value={memoValue}>{props.children}</Context.Provider>
  );
});

export default Context;
