import type {FC} from 'react';

import IconLockSVG from '@yourcoach/shared/assets/icons/lock-2.svg';

interface Props {
  className?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconLock: FC<Props> = ({
  className = '',
  height = '100%',
  viewBox = '2 2 22 22',
  width = '100%',
}) => {
  return (
    <IconLockSVG
      className={`${className}`}
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default IconLock;
