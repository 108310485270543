import type {FC} from 'react';
import {memo} from 'react';

interface Props {
  className?: string;
}

const IconArrow: FC<Props> = ({className = ''}) => {
  return (
    <svg
      className={className}
      fill="none"
      height="14"
      viewBox="0 0 8 14"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7 7L1 13"
        stroke="#819AA9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default memo(IconArrow);
