import type {FC} from 'react';
import {memo} from 'react';

import IconListSVG from '@yourcoach/shared/assets/icons/list.svg';

interface Props {
  customClass?: string;
  height?: string;
  width?: string;
}

const IconList: FC<Props> = ({
  customClass = '',
  height = '100%',
  width = '100%',
}) => {
  return (
    <IconListSVG
      className={`${customClass}`}
      fill="#819AA9"
      height={width}
      viewBox="5 5 25 25"
      width={height}
    />
  );
};

export default memo(IconList);
