import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {t} from 'i18n-js';
import {Observer} from 'mobx-react';

import type {RateConferenceConfig} from '@yourcoach/shared/modules/conference-rating';

import {
  ConferenceRating,
  ConferenceRatingStore,
  DontShowConferenceRatingCheckbox,
} from '@yourcoach/shared/modules/conference-rating';
import {logger} from '@yourcoach/shared/utils/logger';
import {useMobxStore} from '@yourcoach/shared/utils/store';

import {ModalStickyFooter} from '@src/components/ModalNew';

import * as S from './styles';

export const RatingConferenceModal = NiceModal.create<RateConferenceConfig>(
  ({id, isB2b, role, type}) => {
    const modal = useModal();
    const store = useMobxStore(() => new ConferenceRatingStore());

    const getActiveColor = () => {
      if (role === 'coach') {
        return 'global1';
      }

      return 'global2';
    };

    const handleSubmit = async () => {
      try {
        modal.hide();

        await store.sendFeedback(id);
      } catch (e) {
        logger.error(e);
      }
    };

    return (
      <S.Modal
        isOpen={modal.visible}
        onAfterClose={modal.remove}
        onRequestClose={modal.hide}
        title=" "
      >
        <S.ButtonClose onClick={modal.hide}>
          <S.IconClose />
        </S.ButtonClose>
        <ConferenceRating isB2b={isB2b} role={role} store={store} type={type} />
        <ModalStickyFooter position={24}>
          <Observer>
            {() => (
              <S.ButtonSend
                backgroundColor={getActiveColor()}
                disabled={store.isSubmitDisabled}
                onClick={handleSubmit}
              >
                {t('shared.send')}
              </S.ButtonSend>
            )}
          </Observer>
          <DontShowConferenceRatingCheckbox role={role} />
        </ModalStickyFooter>
      </S.Modal>
    );
  },
);
