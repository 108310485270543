import {
  type FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import type {FileApiModel} from '@yourcoach/shared/api/media/file';

import {getFileSrc} from '@yourcoach/shared/utils/entity/file';
import {logger} from '@yourcoach/shared/utils/logger';
import {storageTokenManager} from '@yourcoach/shared/utils/storageToken';

import Loader from '@src/components/Loader/Loader';
import {t} from '@src/i18n';

import styles from './DocumentViewer.module.css';

const I18N_SCOPE = 'DocumentViewer';

export interface Props {
  document: FileApiModel;
}

const getFileSize = async (url: string) => {
  try {
    const response = await fetch(url, {
      // no-cors is required to get blob
      mode: 'no-cors',
    });

    const blob = await response.blob();

    return blob.size;
  } catch (e) {
    logger.error(e);

    return 0;
  }
};

const DEFAULT_REALOD_TIMEOUT = 2000;

const DocumentViewer: FC<Props> = ({document}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const reloadInterval = useRef(0);
  const reloadTimeout = useRef(DEFAULT_REALOD_TIMEOUT);

  const signedSrc = useMemo(() => {
    const fileSrc = getFileSrc(document);
    const secureUrl = storageTokenManager.signUrl(fileSrc.url || '');

    return secureUrl;
  }, [document]);

  const src = useMemo(
    () =>
      `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
        signedSrc,
      )}`,
    [signedSrc],
  );

  useEffect(() => {
    const setup = async () => {
      const fileSize = await getFileSize(signedSrc);

      // calc timeout based on file size in bytes with download speed 250kb/s
      const fileSizeinKb = fileSize / 1024;

      reloadTimeout.current = Math.max(
        DEFAULT_REALOD_TIMEOUT,
        (fileSizeinKb / 250) * 1000,
      );

      setIsReady(true);
    };

    setup();
  }, [signedSrc]);

  const onLoad = useCallback(() => {
    setIsLoading(false);

    clearInterval(reloadInterval.current);
  }, []);

  const reload = useCallback(() => {
    if (!iframeRef.current) {
      return;
    }

    iframeRef.current.src += '';
  }, []);

  useEffect(() => {
    if (isReady) {
      reloadInterval.current = +setInterval(reload, reloadTimeout.current);
    }

    return () => {
      clearInterval(reloadInterval.current);
    };
  }, [isReady, reload]);

  return (
    <div className={classNames('DocumentViewer', styles.Component)}>
      {isReady ? (
        <iframe onLoad={onLoad} ref={iframeRef} src={src}>
          {t([I18N_SCOPE, 'not_supported_message'])}
        </iframe>
      ) : null}
      {isLoading ? (
        <div className={styles.loadingSpinnerContainer}>
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default memo(DocumentViewer);
