import type {FC} from 'react';

import Bell from '@yourcoach/shared/assets/icons/alarm.svg';

interface Props {
  className?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconBell: FC<Props> = ({
  className = '',
  height = '100%',
  viewBox = '3 2 18 22',
  width = '100%',
}) => {
  return (
    <Bell
      className={`IconBell ${className}`}
      fill="#819AA9"
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default IconBell;
