import Color from 'color';
import styled from 'styled-components';

import CloseSquareIcon from '@yourcoach/shared/assets/icons/primary/Close Square.svg';
import {getColor, ms} from '@yourcoach/shared/styles';
import {Button} from '@yourcoach/shared/uikit/Button';

import ModalNew from '@src/components/ModalNew';

export const Modal = styled(ModalNew)`
  max-width: ${ms(430)}!important;

  .body.body {
    padding: 0 ${ms(24)} ${ms(24)} ${ms(24)};
  }

  .header {
    display: none;
  }
`;

export const ButtonClose = styled(Button)`
  height: ${ms(32)};
  width: ${ms(32)};
  z-index: 3;
  padding: 0;
  background: none;
  position: absolute;
  top: ${ms(24)};
  right: ${ms(24)};

  &:hover {
    background: none;
  }
`;

export const ButtonSend = styled(Button)<{backgroundColor: string}>`
  height: ${ms(48)};
  margin-block-start: ${ms(24)};
  background-color: ${p => getColor(p.backgroundColor)(p)};

  &:hover {
    background-color: ${p =>
      Color(getColor(p.backgroundColor)(p)).alpha(0.8).toString()};
  }
`;

export const IconClose = styled(CloseSquareIcon).attrs(p => ({
  fill: getColor('icon3')(p),
}))`
  width: ${ms(32)};
  height: ${ms(32)};
`;
