import type {FC} from 'react';

import UserGroupSVG from '@yourcoach/shared/assets/icons/user-group.svg';

interface Props {
  className?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconUserGroup: FC<Props> = ({
  className = 'icon',
  height = '100%',
  viewBox = '0 0 28 29',
  width = '100%',
}) => {
  return (
    <div className={`${className}`}>
      <UserGroupSVG
        className={className}
        fill="#819AA9"
        height={height}
        viewBox={viewBox}
        width={width}
      />
    </div>
  );
};

export default IconUserGroup;
