import type {Mixpanel} from 'mixpanel-browser';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {API_VERSION, B2B_API_VERSION} from '@root/config';
import mixpanelBrowser from 'mixpanel-browser/src/loaders/loader-module-with-async-recorder';
import yn from 'yn';

import type {ILogger} from '@yourcoach/shared/utils/logger';
import type {Nullable} from '@yourcoach/shared/utils/utility-types';

import {apiRequest} from '@yourcoach/shared/api/utils/apiRequest';
import {envManager} from '@yourcoach/shared/utils/env';

let mixpanel: Nullable<Mixpanel> = null;

export class Logger implements ILogger {
  error: Required<ILogger>['error'] = error => {
    Bugsnag.notify(error);
  };
  event: Required<ILogger>['event'] = (name, params) => {
    mixpanel?.track(name, params);

    Bugsnag.leaveBreadcrumb(name, params, 'log');

    this.debug('Analytic event:', name, params ?? '');
  };
  logApiRequest: Required<ILogger>['logApiRequest'] = payload => {
    Bugsnag.leaveBreadcrumb(
      'RPC request',
      {
        method: Array.isArray(payload)
          ? `Batch: ${payload.map(item => item.method).join(' ,')}`
          : payload.method,
      },
      'log',
    );
  };
  setUser: Required<ILogger>['setUser'] = user => {
    if (user) {
      mixpanel?.identify(user._id);

      this.setUserProperty('$name', user.name);

      this.setUserProperty(
        'is_coach',
        user.roles.includes('coach') ? 'yes' : 'no',
      );

      this.setUserProperty('is_b2b', envManager.get('isB2b') ? 'yes' : 'no');
    } else {
      mixpanel?.reset();
    }

    Bugsnag.setUser(
      user ? user._id : undefined,
      undefined,
      user ? user.name : undefined,
    );
  };
  setUserProperty: Required<ILogger>['setUserProperty'] = (prop, value) => {
    mixpanel?.people.set(prop, value);

    this.debug('Analytic set user property:', prop, value);
  };

  constructor() {
    if (!__SSR__) {
      this.init();
    }
  }

  init: Required<ILogger>['init'] = () => {
    const isB2b = envManager.get('isB2b');

    mixpanel = yn(process.env.ANALYTICS_ENABLED)
      ? (() => {
          mixpanelBrowser.init(process.env.MIXPANEL_KEY!);

          return mixpanelBrowser;
        })()
      : null;

    Bugsnag.start({
      apiKey: process.env.BUGSNAG_KEY!,
      enabledReleaseStages: ['stage1', 'stage2', 'rc', 'prod'],
      metadata: {
        env: {
          api_version: isB2b ? B2B_API_VERSION : API_VERSION,
          is_b2b: isB2b ? 'yes' : 'no',
        },
      },
      onError: event => {
        if (
          event.originalError.name === 'AbortError' ||
          apiRequest.isCanceledError(event.originalError) ||
          event.originalError.message === 'The network connection was lost.'
        ) {
          return false;
        }
      },
      plugins: [new BugsnagPluginReact()],
      redactedKeys: ['access_token', 'session_token'],
      releaseStage: process.env.APP_ENV,
    });
  };

  private debug(...args: Parameters<(typeof console)['log']>) {
    if (process.env.APP_ENV !== 'prod') {
      Promise.resolve().then(() => window.console.log(...args));
    }
  }
}
