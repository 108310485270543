import 'nprogress/nprogress.css';

import {type FC, useEffect} from 'react';

import nprogress from 'nprogress';
import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles/utils';
import {LoadingSpinner} from '@yourcoach/shared/uikit/LoadingSpinner';
import {Logo} from '@yourcoach/shared/uikit/Logo';
import {View} from '@yourcoach/shared/uikit/View';

const Container = styled(View)`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${getColor('background1')};
`;

export const PageLoading: FC = () => {
  useEffect(() => {
    nprogress.start();

    return () => {
      nprogress.done();
    };
  }, []);

  return (
    <Container>
      <Logo size={75} variant="pwr" />
      <LoadingSpinner size={75} />
    </Container>
  );
};
