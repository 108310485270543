import type {FC} from 'react';
import {memo} from 'react';

import IconTrashSVG from '@yourcoach/shared/assets/icons/trash.svg';

interface Props {
  customClass?: string;
  height?: string;
  width?: string;
}

const IconTrash: FC<Props> = ({
  customClass = '',
  height = '100%',
  width = '100%',
}) => {
  return (
    <IconTrashSVG
      className={`${customClass}`}
      fill="#819AA9"
      height={width}
      width={height}
    />
  );
};

export default memo(IconTrash);
