import '@yourcoach/shared/utils/reset.d';
import 'cross-fetch/polyfill';
import 'intersection-observer';
import 'reflect-metadata';

import {BrowserRouter as Router} from 'react-router-dom';

import {createRoot} from 'react-dom/client';

import {ThemeProvider} from '@yourcoach/shared/styles';

import App from '@src/App';
import {Provider as AppContextProvider} from '@src/context/App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Router>
    <AppContextProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </AppContextProvider>
  </Router>,
);
