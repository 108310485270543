import type {FC} from 'react';
import {memo} from 'react';

import IconCheckSVG from '@yourcoach/shared/assets/icons/check.svg';

interface Props {
  customClass?: string;
  fill?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconCheck: FC<Props> = ({
  customClass = '',
  fill = '#819AA9',
  height = '100%',
  viewBox = '2 2 32 32',
  width = '100%',
}) => {
  return (
    <IconCheckSVG
      className={`${customClass}`}
      fill={fill}
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default memo(IconCheck);
