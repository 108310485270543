import {useHistory, useLocation} from 'react-router-dom';

export const useAppRedirect = (alternativePath?: string) => {
  const history = useHistory();
  const location = useLocation();

  const redirect = (path: string) => {
    const currentPath = decodeURIComponent(document.location.pathname);

    if (currentPath !== path) {
      history.replace(path, location.state);
    } else if (alternativePath) {
      history.replace(alternativePath, location.state);
    }
  };

  return redirect;
};
