import type {FC} from 'react';

import IconNewFolderSVG from '@src/assets/img/new_folder.svg';

interface Props {
  className?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconNewFolder: FC<Props> = ({
  className = '',
  height = '100%',
  viewBox = '0 0 24 24',
  width = '100%',
}) => {
  return (
    <IconNewFolderSVG
      className={className}
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default IconNewFolder;
