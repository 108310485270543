import {
  type FC,
  type LazyExoticComponent,
  memo,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type {RouteProps} from 'react-router-dom';
import {Redirect, Route, useHistory} from 'react-router-dom';

import {autorun} from 'mobx';

import {isUserHasRole} from '@yourcoach/shared/utils/entity/user';

import {useAppRedirect} from '@src/common/useAppRedirect';
import AppContext from '@src/context/App';
import {PageLoading} from '@src/v2/components/PageLoading';

import {getBrowserPath} from '../v2/utils/getBrowserPath';

type Props = RouteProps & {
  forCoach?: boolean;
  publicComponent?: LazyExoticComponent<any>;
};

const PrivateRoute: FC<Props> = ({
  children,
  component,
  forCoach,
  publicComponent,
  render,
  ...rest
}) => {
  const {
    stores: {authStore, currentUserStore},
  } = useContext(AppContext);

  const history = useHistory();
  const redirect = useAppRedirect();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      const sessionIsActive =
        (await authStore.sessionIsActive()) &&
        (await currentUserStore.userExists());

      setIsAuthenticated(sessionIsActive);
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dispose = autorun(() => {
      if (isAuthenticated && !currentUserStore.user) {
        setIsAuthenticated(false);
      }
    });

    return dispose;
  }, [currentUserStore.user, history, isAuthenticated]);

  useEffect(() => {
    const dispose = autorun(() => {
      if (
        isAuthenticated &&
        forCoach &&
        !isUserHasRole('coach') &&
        !publicComponent
      ) {
        redirect('/');
      }
    });

    return dispose;
  }, [forCoach, history, isAuthenticated, publicComponent, redirect]);

  const needShowOnboarding = useMemo(() => {
    const onboardingData = currentUserStore.user?.metadata?.onboarding || {
      is_finished: true,
    };

    return !onboardingData.is_finished;
  }, [currentUserStore.user?.metadata?.onboarding]);

  const needShowChooseAccountType = useMemo(() => {
    const onboardingData = currentUserStore.user?.metadata?.onboarding || {
      account_type: 'client',
    };

    return needShowOnboarding && !onboardingData.account_type;
  }, [currentUserStore.user?.metadata?.onboarding, needShowOnboarding]);

  return (
    <Route
      {...rest}
      // @ts-expect-error: No overload matches this call.
      render={props => {
        const Component = component;

        if (isAuthenticated === null) {
          return null;
        } else if (isAuthenticated) {
          if (
            needShowChooseAccountType &&
            !props.location.pathname.startsWith('/login')
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  // no account type
                  search: '?nat=1',
                  state: {from: window.location.pathname},
                }}
              />
            );
          }

          if (needShowOnboarding) {
            const accountType = (
              currentUserStore.user?.metadata?.onboarding || {
                account_type: 'client',
              }
            ).account_type;

            if (
              accountType === 'coach' &&
              !props.location.pathname.startsWith('/user-profile')
            ) {
              return (
                <Redirect
                  to={{
                    pathname: '/user-profile',
                    state: props.location.state,
                  }}
                />
              );
            }

            if (
              accountType !== 'coach' &&
              !props.location.pathname.startsWith('/onboarding')
            ) {
              return (
                <Redirect
                  to={{
                    pathname: '/onboarding',
                    state: props.location.state,
                  }}
                />
              );
            }
          }

          if (render) {
            return render(props);
          } else if (children) {
            return children;
          } else if (Component) {
            return <Component {...props} />;
          }
        }

        if (props.location.pathname !== 'login') {
          if (publicComponent) {
            const PublicComponent = publicComponent;

            return (
              <Suspense fallback={<PageLoading />}>
                {/* @ts-expect-error: has no properties in common with type 'IntrinsicAttributes' */}
                <PublicComponent {...props} />
              </Suspense>
            );
          }

          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  /* TODO: this is bad. Really really bad. Our flow is a little bit nihuya ne obvious.
                      After logout we redirect to login page. Twice.
                      On the first time  router state is okay, but on the second time it's empty.
                      This is the reason why have lost query string params.
                      We should have a better way to handle this.
                   */
                  from: getBrowserPath(),
                },
              }}
            />
          );
        }

        return null;
      }}
    />
  );
};

export default memo(PrivateRoute);
