import './Modal.styles.css';
import 'overlayscrollbars/overlayscrollbars.css';

import {
  forwardRef,
  memo,
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactModal from 'react-modal';

import classNames from 'classnames';
import Color from 'color';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import styled from 'styled-components';

import {getColor} from '@yourcoach/shared/styles';
import {LoadingSpinner} from '@yourcoach/shared/uikit/LoadingSpinner';
import {View} from '@yourcoach/shared/uikit/View';
import {TestID} from '@yourcoach/shared/utils/test-utils/data-test-id';

import {IconClose} from './icons';

import styles from './Modal.module.css';

const presentedModals: {[key: string]: boolean} = {};

export interface Props extends Omit<ReactModal.Props, 'isOpen'> {
  afterTitleSlot?: () => ReactElement;
  bodyClassName?: string;
  children: ReactNode;
  customScroll?: boolean;
  isDisabled?: boolean;
  isntClosable?: boolean;
  isOpen?: boolean;
  showLoadingOverlay?: boolean;
  title?: (() => ReactElement) | string;
}

ReactModal.setAppElement('#root');

export type ModalRef = {
  hide: () => void;
  show: () => void;
};

const contentClassName = 'modal-content';
const overlayClassName = 'modal-overlay';

let modalId = 0;

const Modal = forwardRef<ModalRef, Props>(
  (
    {
      afterTitleSlot,
      bodyClassName = 'body',
      children,
      customScroll,
      isDisabled,
      isntClosable,
      isOpen: isOpenProp,
      showLoadingOverlay,
      title,
      ...rest
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(isOpenProp || false);

    const id = useRef(modalId++);
    const classNameId = `Modal-${id.current}`;

    const refIsUsed = useRef(false);

    useImperativeHandle(ref, () => ({
      hide: () => {
        refIsUsed.current = false;

        hide();
      },
      show: () => {
        refIsUsed.current = true;

        show();
      },
    }));

    const show = useCallback(() => {
      setIsOpen(true);

      presentedModals[id.current] = true;
    }, []);

    const hide = useCallback(() => {
      setIsOpen(false);

      delete presentedModals[id.current];
    }, []);

    const onRequestCloseCb = useCallback(
      e => {
        hide();

        if (rest.onRequestClose) {
          rest.onRequestClose(e);
        }
      },
      [hide, rest],
    );

    useEffect(() => {
      if (!refIsUsed.current) {
        if (isOpenProp !== isOpen) {
          isOpenProp ? show() : hide();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenProp]);

    useEffect(() => {
      isOpenProp ? show() : hide();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const disableScrollClass = 'no-scroll';
      const backgroundEffectClass = 'blur';

      const body = document.body;
      const bg = document.getElementById('allContent');

      if (isOpen) {
        body.classList.add(disableScrollClass);
        bg?.classList.add(backgroundEffectClass);
      }

      return () => {
        body.classList.remove(disableScrollClass);
        bg?.classList.remove(backgroundEffectClass);
      };
    }, [isOpen]);

    const getHeaderEl = useCallback(
      () => document.querySelector(`.${classNameId} .header-wrapper`),
      [classNameId],
    );

    const fitCustomScroll = useCallback(() => {
      if (customScroll) {
        /**
         * dirty hack to fit scrollbars
         */
        const headerEl = getHeaderEl();

        const scrollContainerEl = document.querySelector(
          `.${classNameId} .${styles.customScroll}`,
        );

        /**
         * @see ./Modal.styles.css:33
         */
        const verticalPadding = 20;
        const headerHeight = headerEl ? headerEl.clientHeight : 0;

        if (scrollContainerEl) {
          // @ts-expect-error: Property 'style' does not exist on type 'Element'
          scrollContainerEl.style.maxHeight = `calc(100vh - ${
            headerHeight + verticalPadding * 2
          }px)`;
        }
      }
    }, [classNameId, customScroll, getHeaderEl]);

    const [headerResizeObserver] = useState(
      () => new ResizeObserver(fitCustomScroll),
    );

    useEffect(() => {
      return () => {
        headerResizeObserver.disconnect();
      };
    }, [headerResizeObserver]);

    const onAfterOpenCb = () => {
      const header = getHeaderEl();

      if (header) {
        headerResizeObserver.observe(header);
      }

      fitCustomScroll();

      rest.onAfterOpen?.();
    };

    const renderBody = () => (
      <div
        className={classNames(
          styles.body,
          customScroll && styles.customScroll,
          !customScroll && bodyClassName,
        )}
      >
        {customScroll ? (
          <div
            className={classNames(
              styles.customScrollContainer,
              customScroll && bodyClassName,
            )}
          >
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    );

    const isAbleToClose = !(showLoadingOverlay || isntClosable);

    return (
      <ReactModal
        closeTimeoutMS={200}
        shouldCloseOnEsc={isAbleToClose}
        shouldCloseOnOverlayClick={isAbleToClose}
        {...rest}
        className={classNames(
          contentClassName,
          isDisabled && `${contentClassName}-disabled`,
          customScroll && `${contentClassName}-custom-scroll`,
          rest.className as string,
        )}
        isOpen={isOpen}
        onAfterOpen={onAfterOpenCb}
        onRequestClose={onRequestCloseCb}
        overlayClassName={overlayClassName}
      >
        <div className={`Modal ${styles.Modal} ${classNameId}`}>
          {(title || afterTitleSlot) && (
            <div className={classNames(styles.headerWrapper, 'header-wrapper')}>
              <div className={classNames(styles.header, 'header')}>
                {title && (
                  <div
                    className={classNames(styles.headerText, 'header-text')}
                    data-test-id={TestID.modal.text.title}
                  >
                    {typeof title === 'function' ? title() : title}
                  </div>
                )}
                {!isntClosable ? (
                  <button
                    className={styles.closeButton}
                    data-test-id={TestID.modal.button.close}
                    onClick={onRequestCloseCb}
                  >
                    <IconClose />
                  </button>
                ) : null}
              </div>
              {afterTitleSlot?.()}
            </div>
          )}
          {customScroll ? (
            <OverlayScrollbarsComponent
              defer
              options={{
                scrollbars: {
                  autoHide: 'scroll',
                },
              }}
            >
              {renderBody()}
            </OverlayScrollbarsComponent>
          ) : (
            renderBody()
          )}
          {showLoadingOverlay ? (
            <LoadingOverlay>
              <LoadingSpinner size={50} />
            </LoadingOverlay>
          ) : null}
        </div>
      </ReactModal>
    );
  },
);

export const ModalStickyFooter = styled.div<{position?: number}>`
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: ${p => p.position || 20}px;
  left: 0;
  right: 0;
  background-color: ${getColor('background1')};
  z-index: 2;

  /* It overlaps scrollable content below the footer */
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${p => -(p.position || 20)}px;
    height: ${p => p.position || 20}px;
    background-color: ${getColor('background1')};
  }
`;

// TODO: Move to styles during refactoring
const LoadingOverlay = styled(View)`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p =>
    Color(getColor('background1')(p)).alpha(0.8).toString()};
  z-index: 2;
`;

/**
 * @deprecated
 * Use `Modal` from `@yourcoach/shared/components/Modal` instead
 */
export default memo(Modal);
