import {useContext} from 'react';
import {useLocation} from 'react-router-dom';

import {logger} from '@yourcoach/shared/utils/logger';
import {storage} from '@yourcoach/shared/utils/storage';

import AppContext from '@src/context/App';
import localAppStore from '@src/context/appStore';

import {PATH_TYPE} from '../routes/utils';

export const useSetReturnPath = () => {
  const location = useLocation();

  if (
    location.pathname !== '/' &&
    location.pathname !== PATH_TYPE.yourSpace &&
    location.pathname !== PATH_TYPE.login
  ) {
    const path = `${location.pathname}${location.search}`;

    storage.setItem('returnPath', path);

    return () => {
      localAppStore.setReturnPath(path);
    };
  } else {
    storage.setItem('returnPath', '');

    return () => {
      localAppStore.setReturnPath('');
    };
  }
};

export const useIsAuth = (fnTrue = () => {}, fnFalse = () => {}) => {
  const {
    stores: {authStore, currentUserStore},
  } = useContext(AppContext);

  const isAuth = async (fnTrueIn = () => {}, fnFalseIn = () => {}) => {
    try {
      const sessionIsActive = await authStore.sessionIsActive();
      const userExists = await currentUserStore.userExists();

      if (sessionIsActive && userExists) {
        localAppStore!.setIsAuth(true);

        fnTrueIn();
      } else {
        localAppStore!.setIsAuth(false);
        fnFalseIn();
      }
    } catch (error) {
      logger.error(error);
    }
  };

  return async () => await isAuth(fnTrue, fnFalse);
};
