import type {FC} from 'react';

import IconNewSettingsSVG from '@src/assets/img/newSetting21092020.svg';

interface Props {
  className?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconNewSettings: FC<Props> = ({
  className = 'ico',
  height = '100%',
  viewBox = '2 2 16 16',
  width = '100%',
}) => {
  return (
    <IconNewSettingsSVG
      className={className}
      fill="#819AA9"
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default IconNewSettings;
