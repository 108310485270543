import type {FC} from 'react';

import IconCloseUserSVG from '@src/assets/img/closed_user.svg';

interface Props {
  className?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconCloseUser: FC<Props> = ({
  className = 'ico',
  height = '100%',
  viewBox = '0 0 22 20',
  width = '100%',
}) => {
  return (
    <IconCloseUserSVG
      className={className}
      fill="#819AA9"
      height={width}
      viewBox={viewBox}
      width={height}
    />
  );
};

export default IconCloseUser;
