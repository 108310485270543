import type {FC} from 'react';
import {memo} from 'react';

import styled from 'styled-components';

import {LoadingSpinner} from '@yourcoach/shared/uikit/LoadingSpinner';
import {View} from '@yourcoach/shared/uikit/View';

interface Props {
  customClass?: string;
  height?: string;
  size?: number;
  width?: string;
}

const Container = styled(View)`
  justify-content: center;
`;

const Loader: FC<Props> = ({
  customClass = '',
  height = '100',
  size,
  width = '100',
}) => {
  if (size !== undefined) {
    width = `${size}`;
    height = `${size}`;
  }

  return (
    <Container className={customClass}>
      <LoadingSpinner size={size || parseInt(height || width, 10)} />
    </Container>
  );
};

export default memo(Loader);
