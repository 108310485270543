import {t} from '@src/i18n';

const I18N_SCOPE = 'MainMenu';

export const labelCoaches = () => t([I18N_SCOPE, 'Coaches']);

export const labelCommunity = () => t([I18N_SCOPE, 'Community']);

export const labelTutorials = () => t([I18N_SCOPE, 'Tutorials']);

export const labelContent = () => t('shared.content');

export const labelMyCoaches = (count = 2) =>
  t([I18N_SCOPE, 'MyCoaches'], {
    count,
  });

export const labelDashboard = () => t([I18N_SCOPE, 'Dashboard']);

export const labelToDos = () => t([I18N_SCOPE, 'To-dos']);

export const labelChats = () => t([I18N_SCOPE, 'Chats']);

export const labelConference = () => t([I18N_SCOPE, 'Conference']);

export const labelSettings = () => t([I18N_SCOPE, 'Settings']);

export const labelGoals = () => t([I18N_SCOPE, 'Goals']);

export const labelProfile = () => t([I18N_SCOPE, 'Profile']);
