export const API_VERSION = '10.0';

const BASE_TITLE = 'YourCoach Health';

const ENV_TITLE =
  process.env.APP_ENV !== 'prod' ? process.env.APP_ENV!.toUpperCase() : '';

export const TITLE = [BASE_TITLE, ENV_TITLE].filter(Boolean).join(' | ');

export const DATE_FORMAT = 'YYYY-MM-DD';

export const ASSETS_URL = 'https://assets.yourcoach.health';

export const TOS_URL = `${ASSETS_URL}/tos.pdf`;

export const PRIVACY_POLICY_URL = `${ASSETS_URL}/privacy-policy.pdf`;

export const DEFAULT_USER_NAME = 'User Name';

export const ACCOUNT_STORAGE_KEY = 'account';

// B2B

export const B2B_API_VERSION = '9.0';

export const IS_WIDGET_ON_MOBILE_KEY = 'is_widget_on_mobile';
