import {action, observable} from 'mobx';

import {apiRequest} from '@yourcoach/shared/api/utils/apiRequest';
import {getExcludedEvents} from '@yourcoach/shared/modules/notifications/stores/Notifications/utils';
import {delay} from '@yourcoach/shared/utils/async';

// @ts-expect-error: TODO: fix this
import mp3 from './../../click.mp3';
import icon from './../../favicon.png';
import iconDot from './../../favicon-dot.png';
import iconDotGreen from './../../favicon-dot-green.png';

export {getExcludedEvents};

interface IAppStore {
  _fetchUnreadEventsCount(): Promise<number>;
  addBreadcrumb(breadcrumb: IBreadcrumb): void;
  addBreadcrumbs(breadcrumbs: IBreadcrumb[]): void;
  addLinksBreadcrumbs(obj: IBreadcrumb): void;
  audio: HTMLAudioElement | null;
  breadcrumbs: IBreadcrumb[];
  clearAllLinksBreadcrumbs(): void;
  clearBreadcrumbs(): void;
  clearLinksBreadcrumbs(name: string): void;
  isAppLoading: boolean;
  isAudioPlay: boolean;
  isAuth: boolean;
  isLinksBreadcrumbsHaveProp(name: string): boolean;
  isTopNotificationsOpen: boolean;
  linksBreadcrumbs: ILinksBreadcrumbs;
  postCreatedNotification: number;
  returnPath: string;
  setFavIcon(): void;
  setIsAppLoading(isAppLoading: boolean): void;
  setIsAuth(val: boolean): void;
  setIsTopNotificationsOpen(isTopNotificationsOpen: boolean): void;
  setPostCreatedNotification(postCreatedNotification: number): void;
  setReturnPath(path: string): void;
  setSupportPostCreatedNotification(
    supportPostCreatedNotification: number,
  ): void;
  setTopNotification(topNotification: number): void;
  supportPostCreatedNotification: number;
  topNotification: number;
}

interface ILinksBreadcrumbs {
  [name: string]: string;
}

interface IBreadcrumb {
  name: string;
  path: string;
}

export const POST_EVENTS: Event['type'][] = [
  'course_started',
  'membership_frozen',
  'membership_unfrozen',
  'post_created',
];

const appStore: IAppStore = observable<IAppStore>(
  {
    async _fetchUnreadEventsCount() {
      try {
        const result = await apiRequest({
          method: 'user.events.count',
          params: {
            query: [
              ['type', '!in', getExcludedEvents()],
              ['read', '==', null],
            ],
          },
        });

        this.setTopNotification(result._count);

        return result._count;
      } catch (error) {
        await delay(2000);

        return this._fetchUnreadEventsCount();
      }
    },
    addBreadcrumb(breadcrumb: IBreadcrumb) {
      this.breadcrumbs.push(breadcrumb);
    },
    addBreadcrumbs(breadcrumbs: IBreadcrumb[]) {
      this.breadcrumbs = breadcrumbs;
    },
    addLinksBreadcrumbs(obj: IBreadcrumb) {
      this.linksBreadcrumbs[obj.name] = obj.path;
    },
    audio: typeof Audio !== 'undefined' ? new Audio() : null,
    breadcrumbs: [],
    clearAllLinksBreadcrumbs() {
      this.linksBreadcrumbs = {};
    },
    clearBreadcrumbs() {
      this.breadcrumbs = [];
    },
    clearLinksBreadcrumbs(name: string) {
      delete this.linksBreadcrumbs[name];
    },
    isAppLoading: true,
    isAudioPlay: false,
    isAuth: false,
    isLinksBreadcrumbsHaveProp(name: string) {
      return typeof this.linksBreadcrumbs[name] !== 'undefined';
    },
    isTopNotificationsOpen: false,
    linksBreadcrumbs: {},
    postCreatedNotification: 0,
    returnPath: '/',
    setFavIcon() {
      if (this.postCreatedNotification > 0) {
        // @ts-expect-error: TODO: fix this
        document.getElementById('favicon').href = iconDot;

        if (!this.isAudioPlay) {
          this.audio.src = mp3;
          this.audio.autoplay = true;
        }

        this.isAudioPlay = true;
      } else if (this.topNotification > 0) {
        // @ts-expect-error: TODO: fix this
        document.getElementById('favicon').href = iconDotGreen;

        if (!this.isAudioPlay) {
          this.audio.src = mp3;
          this.audio.autoplay = true;
        }

        this.isAudioPlay = true;
      } else {
        // @ts-expect-error: TODO: fix this
        document.getElementById('favicon').href = icon;
        this.isAudioPlay = false;
      }
    },
    setIsAppLoading(isAppLoading: boolean) {
      this.isAppLoading = isAppLoading;
    },
    setIsAuth(val: boolean) {
      this.isAuth = val;
    },
    setIsTopNotificationsOpen(isTopNotificationsOpen: boolean) {
      this.isTopNotificationsOpen = isTopNotificationsOpen;
    },
    setPostCreatedNotification(postCreatedNotification: number) {
      this.postCreatedNotification = postCreatedNotification;
      this.setFavIcon();
    },
    setReturnPath(path: string) {
      this.returnPath = path;
    },
    setSupportPostCreatedNotification(supportPostCreatedNotification: number) {
      this.supportPostCreatedNotification = supportPostCreatedNotification;
      this.setFavIcon();
    },
    setTopNotification(topNotification: number) {
      this.topNotification = topNotification;
      this.setFavIcon();
    },
    supportPostCreatedNotification: 0,
    topNotification: 0,
  },
  {
    _fetchUnreadEventsCount: action,
    addBreadcrumb: action,
    addBreadcrumbs: action,
    addLinksBreadcrumbs: action,
    audio: observable,
    breadcrumbs: observable,
    clearAllLinksBreadcrumbs: action,
    clearBreadcrumbs: action,
    clearLinksBreadcrumbs: action,
    isAppLoading: observable,
    isAudioPlay: observable,
    isAuth: observable,
    isLinksBreadcrumbsHaveProp: action,
    isTopNotificationsOpen: observable,
    linksBreadcrumbs: observable,
    postCreatedNotification: observable,
    returnPath: observable,
    setFavIcon: action,
    setIsAppLoading: action,
    setIsAuth: action,
    setIsTopNotificationsOpen: action,
    setPostCreatedNotification: action,
    setReturnPath: action,
    setTopNotification: action,
    topNotification: observable,
  },
);

export default appStore;
