import type {FC} from 'react';

import IconCupSVG from '@yourcoach/shared/assets/icons/cup.svg';

interface Props {
  className?: string;
  fill?: string;
  height?: string;
  viewBox?: string;
  width?: string;
}

const IconCup: FC<Props> = ({
  className = '',
  fill,
  height = '100%',
  viewBox = '0 0 20 20',
  width = '100%',
}) => {
  return (
    <IconCupSVG
      className={`${className}`}
      fill={fill ? fill : undefined}
      height={height}
      viewBox={viewBox}
      width={width}
    />
  );
};

export default IconCup;
