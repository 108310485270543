import {t} from '@src/i18n';

const I18N_SCOPE = 'Common';

export const labelDelete = () => t([I18N_SCOPE, 'delete']);

export const labelShare = () => t([I18N_SCOPE, 'Share']);

export const labelClear = () => t([I18N_SCOPE, 'clear']);

export const labelEdit = () => t([I18N_SCOPE, 'edit']);

export const labelCreate = () => t([I18N_SCOPE, 'Create']);

export const labelUpdate = () => t([I18N_SCOPE, 'Update']);

export const labelMove = () => t([I18N_SCOPE, 'move']);

export const labelOpen = () => t([I18N_SCOPE, 'Open']);

export const labelNoResults = () => t([I18N_SCOPE, 'NoResults']);

export const labelDuplicate = () => t([I18N_SCOPE, 'duplicate']);

export const labelSend = () => t([I18N_SCOPE, 'send']);

export const labelBack = () => t([I18N_SCOPE, 'Back']);

export const labelContinue = () => t([I18N_SCOPE, 'Continue']);

export const labelUse = () => t([I18N_SCOPE, 'Use']);

export const labelDetails = () => t([I18N_SCOPE, 'Details']);

export const labelAdd = () => t([I18N_SCOPE, 'Add']);

export const labelAddEmail = () => t([I18N_SCOPE, 'AddEmail']);

export const labelAddEmails = () => t([I18N_SCOPE, 'AddEmails']);

export const labelYouEnteredAnIncorrectEmail = () =>
  t([I18N_SCOPE, 'YouEnteredAnIncorrectEmail']);

export const labelYouDontHaveAnyClients = () =>
  t([I18N_SCOPE, 'YouDontHaveAnyClients']);

export const labelLibraryPathName = () => t([I18N_SCOPE, 'libraryPathName']);

export const labelSessionNotesPathName = () =>
  t([I18N_SCOPE, 'sessionNotesPathName']);

export const labelMySquadsPathName = () =>
  t([I18N_SCOPE, 'myProgramsPathName']);

export const labelEditPhoto = () => t([I18N_SCOPE, 'edit_photo']);

export const labelFinancesPathName = () => t([I18N_SCOPE, 'financesPathName']);

export const labelToolboxPathName = () => t([I18N_SCOPE, 'toolboxPathName']);

export const labelServicesPathName = () => t([I18N_SCOPE, 'servicesPathName']);

export const labelUserProfileTabPathName = () =>
  t([I18N_SCOPE, 'userProfileTabPathName']);

export const labelCertificationTabPathName = () =>
  t([I18N_SCOPE, 'certificationTabPathName']);

export const labelPaymentsTabPathName = () =>
  t([I18N_SCOPE, 'paymentsTabPathName']);

export const labelJoinedProgramsPathName = () =>
  t([I18N_SCOPE, 'joinedProgramsPathName']);

export const labelContacts = () => t('shared.contacts');
