import {useCallback} from 'react';

import NiceModal, {useModal} from '@ebay/nice-modal-react';

import type {Props} from '@src/modules/library/DocumentViewer';

import ModalNew from '@src/components/ModalNew';
import DocumentViewer from '@src/modules/library/DocumentViewer';

export const DocumentViewerNiceModal = NiceModal.create<
  Props & {title: string}
>(({document, title}) => {
  const modal = useModal();

  const onRequestClose = useCallback(() => {
    modal.resolve();
    modal.hide();
  }, [modal]);

  return (
    <ModalNew
      bodyClassName="body"
      isOpen={modal.visible}
      onAfterClose={modal.remove}
      onRequestClose={onRequestClose}
      title={title}
    >
      <DocumentViewer document={document} />
    </ModalNew>
  );
});
