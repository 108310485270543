import type {FC} from 'react';
import {useHistory} from 'react-router';

import type {WithChildren} from '@yourcoach/shared/utils/utility-types';

import {ContainerExtend} from '@yourcoach/shared/IoC/components/ContainerExtend';

import {createNavigationModule} from '@src/v2/services/navigation/Navigation.service';

export const NavigationServiceProvider: FC<WithChildren> = ({children}) => {
  const history = useHistory();

  return (
    <ContainerExtend extensions={createNavigationModule(history)}>
      {children}
    </ContainerExtend>
  );
};
