import {t} from '@src/i18n';

const I18N_SCOPE = 'Questionnaire';

export const labelQuestionnaire = () => t([I18N_SCOPE, 'Questionnaire']);

export const labelYouAreAwesome = () => t([I18N_SCOPE, 'YouAreAwesome']);

export const labelDescription = () => t([I18N_SCOPE, 'Description']);

export const labelSection = () => t([I18N_SCOPE, 'Section']);

export const labelAddSection = () => t([I18N_SCOPE, 'AddSection']);

export const labelSectionName = () => t([I18N_SCOPE, 'SectionName']);

export const labelAddQuestion = () => t([I18N_SCOPE, 'AddQuestion']);

export const labelDescriptionName = () => t([I18N_SCOPE, 'DescriptionName']);

export const labelProgress = () => t([I18N_SCOPE, 'Progress']);

export const labelStart = () => t([I18N_SCOPE, 'Start']);

export const labelUser = () => t([I18N_SCOPE, 'User']);

export const labelYourcoach = () => t([I18N_SCOPE, 'Yourcoach']);

export const labelQuestions = () => t([I18N_SCOPE, 'Questions']);

export const labelQuestionsCount = count =>
  t([I18N_SCOPE, 'questions_count'], {count: count});

export const labelAbout = () => t([I18N_SCOPE, 'About']);

export const labelStatus = () => t([I18N_SCOPE, 'Status']);

export const labelTypeQuestion = () => t([I18N_SCOPE, 'TypeQuestion']);

export const labelOther = () => t([I18N_SCOPE, 'Other']);

export const labelTypeAnswer = () => t([I18N_SCOPE, 'TypeAnswer']);

export const labelIsThisFieldRequired = () =>
  t([I18N_SCOPE, 'IsThisFieldRequired']);

export const labelAddAnswer = () => t([I18N_SCOPE, 'AddAnswer']);

export const labelName = () => t([I18N_SCOPE, 'Name']);

export const labelAnswered = () => t([I18N_SCOPE, 'Answered']);

export const labelCompleted = () => t([I18N_SCOPE, 'Completed']);

export const labelComplete = () => t([I18N_SCOPE, 'Complete']);

export const labelInProgress = () => t([I18N_SCOPE, 'InProgress']);

export const labelResults = () => t([I18N_SCOPE, 'Results']);

export const labelSent = () => t([I18N_SCOPE, 'Sent']);

export const labelBackToFormsAndQrs = () =>
  t([I18N_SCOPE, 'BackToFormsAndQrs']);

export const labelSendQuestionnaire = () =>
  t([I18N_SCOPE, 'SendQuestionnaire']);

export const labelChooseTemplate = () => t([I18N_SCOPE, 'ChooseTemplate']);

export const labelUseThisTemplate = () => t([I18N_SCOPE, 'UseThisTemplate']);

export const labelByName = () => t([I18N_SCOPE, 'ByName']);

export const labelByEmail = () => t([I18N_SCOPE, 'ByEmail']);

export const labelClients = () => t([I18N_SCOPE, 'Clients']);

export const labelNoDescription = () => t([I18N_SCOPE, 'NoDescription']);

export const labelSectionTemplates = () => t([I18N_SCOPE, 'SectionTemplates']);

export const labelSeeTemplates = () => t([I18N_SCOPE, 'SeeTemplates']);

export const labelNewQuestion = () => t([I18N_SCOPE, 'NewQuestion']);

export const labelName___ = () => t([I18N_SCOPE, 'Name___']);

export const labelTextField = () => t([I18N_SCOPE, 'TextField']);

export const labelSingleChoice = () => t([I18N_SCOPE, 'SingleChoice']);

export const labelMultipleChoice = () => t([I18N_SCOPE, 'MultipleChoice']);

export const labelTypeOption = () => t([I18N_SCOPE, 'TypeOption']);

export const labelFreeUserResponse = () => t([I18N_SCOPE, 'FreeUserResponse']);

export const labelAddChoices = () => t([I18N_SCOPE, 'AddChoices']);

export const labelAddOther = () => t([I18N_SCOPE, 'AddOther']);

export const labelNewAnswer = () => t([I18N_SCOPE, 'NewAnswer']);

export const labelChooseQuestionType = () =>
  t([I18N_SCOPE, 'ChooseQuestionType']);

export const labelDescribeYourQuestionnaireHere___ = () =>
  t([I18N_SCOPE, 'DescribeYourQuestionnaireHere___']);

export const labelQuestionnaireName = () =>
  t([I18N_SCOPE, 'QuestionnaireName']);

export const labelThisFieldIsMandatoryAndMustContainAtLeast3Characters = () =>
  t([I18N_SCOPE, 'ThisFieldIsMandatoryAndMustContainAtLeast3Characters']);

export const labelYouCanUseCompletedSections = () =>
  t([I18N_SCOPE, 'YouCanUseCompletedSections']);

export const labelSectionDescription = () =>
  t([I18N_SCOPE, 'SectionDescription']);

export const labelThisFieldIsMandatoryAndMustContainAtLeast1_255Characters =
  () =>
    t([I18N_SCOPE, 'ThisFieldIsMandatoryAndMustContainAtLeast1_255Characters']);

export const labelAddToMyQuestionnaire = () =>
  t([I18N_SCOPE, 'AddToMyQuestionnaire']);

export const labelCreateNewQuestionnaire = () =>
  t([I18N_SCOPE, 'CreateNewQuestionnaire']);

export const labelSearchForClients = () => t([I18N_SCOPE, 'SearchForClients']);

export const labelSearchForQuestionnaires = () =>
  t([I18N_SCOPE, 'SearchForQuestionnaires']);

export const labelYouCanUseCompletedQuestionnaires = () =>
  t([I18N_SCOPE, 'YouCanUseCompletedQuestionnaires']);

export const labelThisQuestionIsRequiredAndMustContain = () =>
  t([I18N_SCOPE, 'ThisQuestionIsRequiredAndMustContain']);

export const labelThisQuestionIsRequired = () =>
  t([I18N_SCOPE, 'ThisQuestionIsRequired']);

export const labelCongratulationsEnd = () =>
  t([I18N_SCOPE, 'CongratulationsEnd']);

export const labelCongratulationBegin = () =>
  t([I18N_SCOPE, 'CongratulationsBegin']);

export const labelYouHaveAlreadyTakenThisQuestionnaire = () =>
  t([I18N_SCOPE, 'YouHaveAlreadyTakenThisQuestionnaire']);

export const labelCreateQuestionnaire = () =>
  t([I18N_SCOPE, 'CreateQuestionnaire']);
