import type {FC, SVGProps} from 'react';

import {makeAutoObservable, reaction} from 'mobx';

import type {WithReactionDispose} from '@yourcoach/shared/utils/store';

import {currentUserStore} from '@yourcoach/shared/api/user';
import {
  FlagManager,
  ReactionDisposeManager,
} from '@yourcoach/shared/utils/store';

import AdvocateHealthLogo from './logos/advocate-health.svg';
import AetnaCVSHealthLogo from './logos/aetna-cvs-health.svg';
import EvernorthLogo from './logos/evernorth.svg';
import HeadspaceLogo from './logos/headspace.svg';
import MeQLogo from './logos/me-q.svg';
import DummyLogo from './logos/your-company.svg';

type Partner = {
  color: string;
  colorifyLogo: boolean;
  id: string;
  logo: FC<SVGProps<SVGElement>>;
  name: string;
  shortName: string;
};

const PARTNERS: Map<string, Partner> = new Map([
  [
    'advocatehealth',
    {
      color: '#46749b',
      colorifyLogo: true,
      id: 'advocatehealth',
      logo: AdvocateHealthLogo,
      name: 'Advocate Health',
      shortName: 'AdvH',
    },
  ],
  [
    'aetnacvshealth',
    {
      color: '#8932AF',
      colorifyLogo: true,
      id: 'aetnacvshealth',
      logo: AetnaCVSHealthLogo,
      name: 'Aetna CVS Health',
      shortName: 'ACVSH',
    },
  ],
  [
    'evernorth',
    {
      color: '#265B65',
      colorifyLogo: true,
      id: 'evernorth',
      logo: EvernorthLogo,
      name: 'Evernorth',
      shortName: 'EvN',
    },
  ],
  [
    'headspace',
    {
      color: '#EF853B',
      colorifyLogo: false,
      id: 'headspace',
      logo: HeadspaceLogo,
      name: 'headspace',
      shortName: 'hdspace',
    },
  ],
  [
    'meq',
    {
      color: '#e74e2e',
      colorifyLogo: true,
      id: 'meq',
      logo: MeQLogo,
      name: 'meQ',
      shortName: 'meq',
    },
  ],
  [
    'your_company',
    {
      color: '#767d5e',
      colorifyLogo: true,
      id: 'your_company',
      logo: DummyLogo,
      name: 'Your Company',
      shortName: 'YrC',
    },
  ],
]);

/**
 * The class stores data for B2C demo.
 *
 * To start the demo
 * - go to the /login?demo=partner_id
 * - default code is DEMO2023, if it's not exist, create it in the admin panel
 */
export class B2CDemoStore implements WithReactionDispose {
  static ACTIVATION_PARAM = 'demo';
  static COLOR_PARAM = 'color';
  clientName: null | string = null;
  code: null | string = null;
  readonly disposer = new ReactionDisposeManager();
  isActive = new FlagManager();
  partner: null | Partner = null;
  private _color: null | string = null;

  constructor() {
    makeAutoObservable(this);

    this.setupReactions();
  }

  init(pid: string) {
    const partner = PARTNERS.get(pid);

    if (!partner) {
      return;
    }

    this.partner = partner;

    this.isActive.setTrue();
  }

  get color() {
    return this._color || this.partner?.color;
  }

  get searchString() {
    const parts = [
      [B2CDemoStore.ACTIVATION_PARAM, this.partner?.id],
      this._color
        ? [B2CDemoStore.COLOR_PARAM, this._color.replace('#', '')]
        : null,
    ];

    return parts
      .filter(Boolean)
      .map(part => part!.join('='))
      .join('&');
  }

  setClientName(clientName: string) {
    this.clientName = clientName;
  }

  setCode(code: string) {
    this.code = code;
  }

  setColor(color: null | string) {
    if (!color) {
      this._color = null;

      return;
    }

    if (!color?.startsWith('#')) {
      color = `#${color}`;
    }

    // check if it's a valid hex color
    if (!/^#[\da-f]{6}$/i.test(color)) {
      return;
    }

    this._color = color;
  }

  private setupReactions() {
    this.disposer.watch(
      'code',
      reaction(
        () => currentUserStore.user,
        user => {
          // @see pages/Login/steps/VerificationStep.tsx#L377
          if (user?.metadata.b2c_demo?.code) {
            this.code = user.metadata.b2c_demo.code;
          }
        },
        {
          fireImmediately: true,
        },
      ),
    );
  }
}

export const b2cDemoStore = new B2CDemoStore();
