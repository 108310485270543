import {ContainerModule, inject, injectable} from 'inversify';
import {makeObservable} from 'mobx';

import {AppNavigationService} from '@yourcoach/shared/core/system/navigation/app/AppNavigation.service';
import {BaseNavigationService} from '@yourcoach/shared/core/system/navigation/base/BaseNavigation.service';
import {registerBaseNavigation} from '@yourcoach/shared/core/system/navigation/base/registerBaseNavigation';

import {PathBuilderService} from '@src/v2/services/PathBuilderService';

@injectable()
export class NavigationServiceImpl implements AppNavigationService {
  @inject(BaseNavigationService) baseNavigation: BaseNavigationService;
  goToEvent = (config: AppNavigationService.GoToEvent) => {
    const path = PathBuilderService.toEventPage(config.id, config.state);

    this.baseNavigation.push(path);
  };
  goToTodos = (config: AppNavigationService.GoToTodos) => {
    const path = PathBuilderService.toTodos(config);

    this.baseNavigation.push(path);
  };

  constructor() {
    makeObservable(this);
  }
}

export const createNavigationModule = (history): ContainerModule[] => {
  const baseNavigationModule = registerBaseNavigation(service => {
    service.setupHistory(history);

    return service;
  });
  const appNavigationModule = new ContainerModule(bind => {
    bind<AppNavigationService>(AppNavigationService)
      .to(NavigationServiceImpl)
      .inSingletonScope();
  });

  return [baseNavigationModule, appNavigationModule];
};
